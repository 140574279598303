.splitit {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  background-color: #282c34;
  text-align: center;
  position: relative;
}

.container {
  text-align: center;
  width: 100%;  
  position: absolute;
  top: 10%;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

header.header {
  font-size: 6em;
  overflow: hidden;
  margin-bottom: 13px;
  color: #EAC41C;
  font-family: 'Nabla', cursive;
}

.loading {
  width: 360px;
  color: white;
  text-align: center;
}
.loading .header {
  display: block;
  margin-bottom: 7px;
}
.loading .message {
  display: block;
  margin-bottom: 7px;
  font-size: 13px;
  font-style: italic;
  margin-top: 7px;
  transition: 5s all ease-in-out;
}

.error {
  margin-bottom: 13px;
  margin-left: 13px;
  margin-right: 13px;
  width: fit-content;
  white-space: nowrap;
}

.button {
  text-transform: none !important;
  font-family: initial !important;
}
